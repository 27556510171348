import { getUserList, queryIdTree, ajaxGetDictItems } from '@/api/api'
import { postAction, getAction, putAction } from '@/api/manage'
export const OpenDrawerMixins = {
  data() {
    return {
      drawerShow: false,
      isNewForm: false,
      isEditForm: false,
      stageList: [],
      userList: [],
      departList: [],
      selectedUsers: [],
      selectedDepart: '',
      selectedDeparts: [],
      defaultDeparts: [],
      positionList: [],
      assistantUsers: [],
      platformList: [],
      selcetedAssistantUsers: [],
      positionSelected: '',
      curRow: {},
      clickRow: (record, index) => ({
        on: {
          click: () => {
            this.openDetail(record)
          }
        }
      })
    }
  },
  methods: {
    getUserList() {
      getUserList({ pageSize: 1000 }).then(({ success, data }) => {
        this.userList = data.records
      })
    },
    getStageList() {
      getAction('/stage/list').then(({ success, data }) => {
        this.stageList = data
        console.log(data)
      })
    },
    getDepartList(departIds) {
      if (departIds) {
        return queryIdTree().then(({ data }) => {
          this.departList = data
          this.departList.forEach(item => {
            item.disabled = true
            item.children.forEach(i => {
              i.disabled = true
              i.children.forEach(v => {
                if (
                  departIds.some(depart => {
                    return v.value == depart.id
                  })
                ) {
                  v.disabled = false
                } else {
                  v.disabled = true
                }
              })
            })
          })
        })
      } else {
        return queryIdTree().then(({ data }) => {
          this.departList = data
          this.departList.forEach(item => {
            item.disabled = true
            item.children.forEach(i => {
              i.disabled = true
            })
          })
        })
      }
    },

    getDicts() {
      ajaxGetDictItems('terms_template_type').then(res => {
        this.typesList = res.data
      })
      ajaxGetDictItems('terms_template_field').then(res => {
        this.typeTitleList = res.data
      })
    },

    changeDepart(v, t, { triggerNode }) {
      this.formData.department = {
        departId: triggerNode.value,
        departName: triggerNode.title
      }
    },
    changeDepartList(keys, t, { triggerNode }) {
      console.log(keys)
      this.formData.departments = keys.map(item => {
        return {
          departId: item
        }
      })
    },

    positionChange(id, option) {
      console.log(option)
      this.getAssistantUsers(id)
    },
    getPosition() {
      getAction('/sys/position/list').then(({ data }) => {
        console.log(data)
        this.positionList = data.records
      })
    },
    getAssistantUsers(id) {
      getAction('/sys/user/query_by_position', {
        positionId: id
      }).then(({ data }) => {
        console.log(data)
        this.assistantUsers = data
      })
    },
    getPlatformList() {
      getAction('/platform/list').then(({ data }) => {
        console.log(data)
        this.platformList = data.records
      })
    },
    openDetail(row) {
      if (this.detailPath) {
        this.$router.push({ path: `${this.detailPath}/${row.id}` })
      } else {
        this.curRow = row
        this.drawerShow = true
        this.isNewForm = false
      }
    },
    openAddForm() {
      this.drawerShow = true
      this.isNewForm = true
    },
    drawerClosed() {
      this.drawerShow = false
      this.isNewForm = false
      this.isEditForm = false
      this.loadData()
    }
  }
}
